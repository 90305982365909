import Amplify, {Auth} from "aws-amplify";

export const awsRegion = "eu-west-1"
const poolCi = {
    userPool: "eu-west-1_3oDXzpWA1",
    userPoolWebClient: "2sqt9mp0ih6mcvaj4gatrhs154",
    centralAccountId: "980036580614",
    identityPoolId: "eu-west-1:f19cec4a-edcf-4ca9-b170-3ec9f8e3f6d7"
}

const poolDev = {
    userPool: "eu-west-1_JUxdbHvNM",
    userPoolWebClient: "69gbebk2qii7ume4ic8ed6igdp",
    centralAccountId: "980036580614",
    identityPoolId: "eu-west-1:f19cec4a-edcf-4ca9-b170-3ec9f8e3f6d7"
}

// update local config here
export const localPool = process.env?.REACT_APP_DEV_ENV ? poolDev : poolCi;

const devConfig = {
    Auth: {
        region: awsRegion,
        userPoolId:"eu-west-1_JUxdbHvNM",
        userPoolWebClientId:"69gbebk2qii7ume4ic8ed6igdp",
        mandatorySignIn: false,
        storage: localStorage,
        authenticationFlowType: "USER_SRP_AUTH",
    },
};

Amplify.configure(devConfig);

// You can get the current config object
const currentConfig = Auth.configure();
export {currentConfig};

export const centralAccountId = "980036580614"
export const identityPoolId = "eu-west-1:3bbc45ea-888e-4965-8383-b460dce2a662"
export const userPoolId = devConfig.Auth.userPoolId
